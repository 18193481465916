<template>
    <div class="chip-select" :class="align">
        <span class="entry" v-for="opt in options" :class="[{ active: opt.value === model }]"
            @click="() => !disabled && emits('update:modelValue', opt.value)">
            {{
                opt.label
            }}
        </span>
    </div>
</template>

<script setup>
const model = defineModel()
const emits = defineEmits(['update:modelValue'])
defineProps({
    options: Array,
    align: String,
    disabled: Boolean
})
</script>